<template>
  <div class="terms">
    <top-panel></top-panel>
    <div class="terms--top">Ochrana Osobních Údajů</div>
    <div class="terms--content">
      <div class="terms--title">Aktualizace: Leden 2020</div>
      <div class="terms--section">
        <div class="terms--text">Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: „GDPR”) je Henry & Holz Ltd, se sídlem Kemp House, 160 City Road, EC1V 2NX, London, United Kingdom (dále jen: „správce“). Kontaktní údaje správce jsou henry@thinktherapy.cz. Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby. Správce nejmenoval pověřence pro ochranu osobních údajů.</div>
      </div>
      <div class="terms--section">
        <div class="terms--title">Důvod zpracování osobních údajů</div>
        <div class="terms--text">1. Zákonným důvodem zpracování osobních údajů je</div>
        <div class="terms--list">
          <div class="terms--list_item">plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR (dále jen „Plnění smlouvy“),</div>
          <div class="terms--list_item">oprávněný zájem správce na poskytování přímého marketingu (zejména pro zasílání obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. f) GDPR (dále jen „Oprávněný zájem“),</div>
          <div class="terms--list_item">Váš souhlas se zpracováním pro účely poskytování přímého marketingu (zejména pro zasílání obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. a) GDPR ve spojení s § 7 odst. 2 zákona č. 480/2004 Sb., o některých službách informační společnosti v případě, že nedošlo k objednávce zboží nebo služby (dále jen „Souhlas“).</div>
        </div>
        <div class="terms--text">2. Ze strany správce nedochází k automatickému individuálnímu rozhodování ve smyslu čl. 22 GDPR. S takovým zpracováním jste poskytl/a svůj výslovný souhlas.</div>
      </div>
      <div class="terms--section">
        <div class="terms--title">Účel zpracování, kategorie, zdroje a příjemci osobních údajů</div>
        <div class="terms--table" role="table">
          <div class="terms--table_row terms--table_row-header" role="rowgroup">
            <div class="terms--table_cell terms--table_cell-title" role="columnheader">Zákonný důvod</div>
            <div class="terms--table_cell terms--table_cell-title" role="columnheader">Účel</div>
            <div class="terms--table_cell terms--table_cell-title" role="columnheader">Údaje</div>
            <div class="terms--table_cell terms--table_cell-title" role="columnheader">Zdroj údajů</div>
            <div class="terms--table_cell terms--table_cell-title" role="columnheader">Příjemci osobních údajů (zpracovatelé)</div>
          </div>
          <div class="terms--table_row" role="rowgroup">
            <div class="terms--table_cell" role="cell">Plnění smlouvy</div>
            <div class="terms--table_cell" role="cell">Odpověď na poptávku zaslanou přes kontaktní formulář</div>
            <div class="terms--table_cell" role="cell">Osobní údaje klientů (e-mail)</div>
            <div class="terms--table_cell" role="cell">Kontaktní formulář</div>
            <div class="terms--table_cell" role="cell">Subdodavatelé, mailingové služby, cloudová úložiště</div>
          </div>
          <div class="terms--table_row" role="rowgroup">
            <div class="terms--table_cell" role="cell">Oprávněný zájem</div>
            <div class="terms--table_cell" role="cell">Poskytování přímého marketingu (zejména pro zasílání obchodních sdělení a newsletterů)</div>
            <div class="terms--table_cell" role="cell">Kontaktní údaje klientů</div>
            <div class="terms--table_cell" role="cell">Informace z objednávek</div>
            <div class="terms--table_cell" role="cell">Mailingové služby, cloudová úložiště, subdodavatelé</div>
          </div>
          <div class="terms--table_row" role="rowgroup">
            <div class="terms--table_cell" role="cell">Oprávněný zájem</div>
            <div class="terms--table_cell" role="cell">Běžná analýza návštěvnosti, odhalování chyb serveru a prevence podvodů a útoků na server</div>
            <div class="terms--table_cell" role="cell">Po dobu 50 měsíců: IP adresy a údaje o prohlížení webu, zobrazené stránky a akce na stránce.</div>
            <div class="terms--table_cell" role="cell">Pohyb uživatele na webu, zobrazení stránky s chybou</div>
            <div class="terms--table_cell" role="cell">Google Analytics, webhostingové služby a případně další analytické služby</div>
          </div>
          <div class="terms--table_row" role="rowgroup">
            <div class="terms--table_cell" role="cell">Souhlas</div>
            <div class="terms--table_cell" role="cell">Získání demografických přehledů ve statistikách návštěvnosti</div>
            <div class="terms--table_cell" role="cell">Cookies třetích stran, demografické údaje (věk, pohlaví, zájmy, zájem o koupi a další kategorie)</div>
            <div class="terms--table_cell" role="cell">Cookie soubor DoubleClick, inzertní ID Android, ID iOS pro inzerenty</div>
            <div class="terms--table_cell" role="cell">Google Analytics</div>
          </div>
          <div class="terms--table_row" role="rowgroup">
            <div class="terms--table_cell" role="cell">Souhlas</div>
            <div class="terms--table_cell" role="cell">Marketing a propagace webu</div>
            <div class="terms--table_cell" role="cell">E-maily, jména potenciálních zákazníků, IP adresy a další technické identifkátory</div>
            <div class="terms--table_cell" role="cell">Formulář pro newsletter</div>
            <div class="terms--table_cell" role="cell">Webhostingová společnost a služby pro rozesílání e-mailů</div>
          </div>
        </div>
      </div>
      <div class="terms--section">
        <div class="terms--title">Doba uchovávání údajů</div>
        <div class="terms--text">1. Pokud není v předchozích bodech uvedeno jinak, Správce uchovává osobní údaje</div>
        <div class="terms--list">
          <div class="terms--list_item">po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem a uplatňování nároků z těchto smluvních vztahů (po dobu 15 let od ukončení smluvního vztahu).</div>
          <div class="terms--list_item">po dobu, než je odvolán souhlas se zpracováním osobních údajů pro účely marketingu, jsou-li osobní údaje zpracovávány na základě souhlasu.</div>
        </div>
        <div class="terms--text">2. Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže.</div>
      </div>
      <div class="terms--section">
        <div class="terms--title">Cookies</div>
        <div class="terms--text">1. Jsou-li v bodě III. mezi osobními údaji uvedeny soubory cookies, platí pro jejich zpracování následující pravidla.</div>
        <div class="terms--list">
          <div class="terms--list_item">Pravidla pro používání nebo zablokování cookies si může každý uživatel nastavit ve svém internetovém prohlížeči, čímž dává najevo svůj souhlas s jejich zpracováním. Můžete se podívat na návod na zablokování cookies.</div>
          <div class="terms--list_item">Uživatel si může nastavit povolení nebo odmítnutí veškerých nebo pouze některých souborů cookies (např. cookies třetích stran). Zablokování souborů cookies může mít negativní vliv na použitelnost webové stránky a služby.</div>
          <div class="terms--list_item">Na tomto webu je návštěvníkům, kteří souhlasí s umístěním cookies do svého prohlížeče prostřednictvím patřičného nastavení chování ke cookies jednotlivých prohlížečů umístěna informace od následujících společností: Google, Inc.</div>
        </div>
        <div class="terms--text">2. Vznesete-li námitku proti zpracování technických cookies nezbytných pro fungování webových stránek, nelze v takovém případě zaručit plnou funkčnost a kompatibilitu webových stránek.</div>
      </div>
      <div class="terms--section">
        <div class="terms--title">Příjemci osobních údajů (subdodavatelé správce)</div>
        <div class="terms--text">1. Správce má v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní organizaci. Příjemci osobních údajů ve třetích zemích jsou poskytovatelé mailingových služeb, úložiště dat a souborů, analytických nástrojů a služeb pro přímý marketing.</div>
      </div>
      <div class="terms--section">
        <div class="terms--title">Vaše práva</div>
        <div class="terms--text">1. Za podmínek stanovených v GDPR máte</div>
        <div class="terms--list">
          <div class="terms--list_item">právo na přístup ke svým osobním údajům dle čl. 15 GDPR,</div>
          <div class="terms--list_item">právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování dle čl. 18 GDPR,</div>
          <div class="terms--list_item">právo na výmaz osobních údajů dle čl. 17 GDPR,</div>
          <div class="terms--list_item">právo vznést námitku proti zpracování dle čl. 21 GDPR,</div>
          <div class="terms--list_item">právo na přenositelnost údajů dle čl. 20 GDPR,</div>
          <div class="terms--list_item">právo odvolat souhlas se zpracováním písemně nebo elektronicky na adresu nebo email správce uvedený v čl. III těchto podmínek.</div>
        </div>
        <div class="terms--text">2. Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaše právo na ochranu osobních údajů.</div>
      </div>
      <div class="terms--section">
        <div class="terms--title">Podmínky zabezpečení osobních údajů</div>
        <div class="terms--text">1. Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení osobních údajů.</div>
        <div class="terms--text">2. Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů v listinné podobě.</div>
        <div class="terms--text">3. Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.</div>
      </div>
      <div class="terms--section">
        <div class="terms--title">Závěrečná ustanovení</div>
        <div class="terms--text">1. Odesláním objednávky z internetového objednávkového formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.</div>
        <div class="terms--text">2. S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového formuláře. Zaškrtnutím souhlasu potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.</div>
        <div class="terms--text">3. Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých internetových stránkách a zároveň Vám zašle novou verzi těchto podmínek na Vaši e-mailovou adresu, kterou jste správci poskytl/a.</div>
      </div>
      <div class="terms--section">
        <div class="terms--text">Tyto podmínky nabývají účinnosti dnem 19. 1. 2020.</div>
      </div>
    </div>
    <bottom-panel></bottom-panel>
  </div>
</template>

<script>
import TopPanel from '@/components/top_panel/TopPanel'
import BottomPanel from '../../components/bottom_panel/BottomPanel'

export default {
  name: 'home',
  components: {
    TopPanel,
    BottomPanel
  }
}
</script>

<style lang="scss">
  @import 'terms';
</style>
